<template>
  <div class="personal-info">
    <!-- <el-image
      style="width: 100%; height: 100%"
      :src="url"
      :fit="'cover'"
    ></el-image> -->
    <div class="info-card">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="74px"
        class="demo-ruleForm"
      >
        <div class="avatar-box">
          <div class="avatar">
            <el-avatar
              :size="111"
              :src="ruleForm.headimage || defaultimg"
            ></el-avatar>
            <p>
              修改头像
              <el-upload
                drag
                action=""
                accept=".jpg,.png,.jpeg"
                :show-file-list="false"
                multiple
                :http-request="customUploadPicture"
              >
              </el-upload>
            </p>
          </div>
        </div>
        <el-form-item label="昵称" prop="nickname">
          <el-input class="input-must" v-model="ruleForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group class="radio-must" v-model="ruleForm.sex">
            <el-radio label="1">男</el-radio>
            <el-radio label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="地区">
          <el-col :span="7">
            <el-select
              v-model="ruleForm.country_id"
              placeholder="- 国 -"
              @change="changeCountry"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in countryList"
                :key="index"
              ></el-option>
            </el-select>
          </el-col>
          <el-col class="line" :span="1"> - </el-col>
          <el-col :span="7">
            <el-select
              v-model="ruleForm.province_id"
              placeholder="- 省 -"
              @change="changeProvince"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in provinceList"
                :key="index"
              ></el-option>
            </el-select>
          </el-col>
          <el-col class="line" :span="1"> - </el-col>
          <el-col :span="7">
            <el-select
              v-model="ruleForm.city_id"
              placeholder="- 市  -"
              @change="changeCity"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in cityList"
                :key="index"
              ></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="生日">
          <el-form-item>
            <el-date-picker
              type="date"
              placeholder="年-月-日"
              v-model="ruleForm.birth"
              value-format="yyyy-MM-dd"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </el-form-item>
        <el-form-item label="职业" prop="occupation">
          <el-input v-model="ruleForm.occupation"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input class="input-must" v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item label="签名" prop="autograph">
          <el-input
            type="textarea"
            resize="none"
            v-model="ruleForm.autograph"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="简介" prop="description">
          <el-input
            type="textarea"
            rows="3"
            resize="none"
            v-model="ruleForm.description"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class="submit"
            type="primary"
            @click="submitForm('ruleForm')"
            >确认提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {
      url: require("../../assets/images/personel-info-bg.png"),
      country: "",
      province: "",
      city: "",
      ruleForm: {
        user_id: localStorage.getItem("user_id"),
        nickname: "",
        headimage: "", //头像
        sex: "1",
        country_id: "",
        province_id: "",
        city_id: "",
        birth: "",
        occupation: "",
        email: "",
        autograph: "",
        description: "",
        address: "" //拼接国+省+市
      },
      defaultimg: require("../../assets/images/headPortrait.png"),
      rules: {
        nickname: [{ message: "请输入昵称", trigger: "blur" }],
        sex: [{ message: "请选择性别", trigger: "change" }],
        // birth: [{ required: true, message: "请选择生日", trigger: "blur" }],
        email: [{ message: "请填写邮箱", trigger: "blur" }]
      },
      countryList: [],
      provinceList: [],
      cityList: []
    };
  },
  async mounted() {
    const { data } = await this.$api.getCountryList();
    if (data.code === 1) {
      this.countryList = data.data;
    }
    // 路由跳转携带参数，请求个人信息来显示出来
    const datainfo = await this.$api.getUserinfo({
      user_id: this.ruleForm.user_id
    });
    if (datainfo.data.code === 1) {
      this.ruleForm = datainfo.data.data;
      if (this.ruleForm.country_id) this.getProvinces(this.ruleForm.country_id);
      if (this.ruleForm.province_id) this.getCitys(this.ruleForm.province_id);
      localStorage.setItem("user_info", JSON.stringify(datainfo.data.data));
    }
  },
  methods: {
    submitForm(formName) {
      let that = this;
      if (!this.ruleForm.address) {
        this.ruleForm.address = this.country + this.province + this.city;
      }
      console.log(this.ruleForm);
      this.$refs[formName].validate(valid => {
        if (valid) {
          // 调用保存接口
          this.$api.editUserinfo(this.ruleForm).then(res => {
            if (res.data.code === 1) {
              console.log(res);
              this.$message({
                message: res.data.msg,
                type: "success"
              });
              setTimeout(async () => {
                const datainfo = await this.$api.getUserinfo({
                  user_id: this.ruleForm.user_id
                });
                if (datainfo.data.code === 1) {
                  localStorage.setItem(
                    "user_info",
                    JSON.stringify(datainfo.data.data)
                  );
                  console.log("set信息");
                  this.$store.state.user.username = datainfo.data.data.username;
                  this.$store.state.user.headimage =
                    datainfo.data.data.headimage;
                }
                that.$router.push({
                  name: "personalCenter",
                  query: { PageUserId: localStorage.getItem("user_id") }
                });
              }, 3000);
            } else {
              this.$message({
                message: res.data.msg,
                type: "warning"
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    customUploadPicture(data) {
      const formData = new FormData();
      formData.append("file", data.file);
      this.$api.postUpImages(formData).then(res => {
        if (res.data.code === 1) {
          console.log(res);
          this.ruleForm.headimage = res.data.data.yuantu;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    async getProvinces(id) {
      const { data } = await this.$api.getProvinceList({ pid: id });
      if (data.code === 1) {
        this.provinceList = data.data;
      }
    },
    async getCitys(id) {
      const { data } = await this.$api.getCityList({ pid: id });
      if (data.code === 1) {
        this.cityList = data.data;
      }
    },
    async changeCountry(val) {
      this.ruleForm.province_id = "";
      this.ruleForm.city_id = "";
      let obj = {};
      obj = this.countryList.find(item => {
        //遍历list的数据
        return item.id === val; //筛选出匹配数据
      });
      this.country = obj.name;
      this.getProvinces(val);
    },
    async changeProvince(val) {
      this.ruleForm.city_id = "";
      let obj = {};
      obj = this.provinceList.find(item => {
        //遍历list的数据
        return item.id === val; //筛选出匹配数据
      });
      this.province = obj.name;
      this.getCitys(val);
    },
    async changeCity(val) {
      let obj = {};
      obj = this.cityList.find(item => {
        //遍历list的数据
        return item.id === val; //筛选出匹配数据
      });
      this.city = obj.name;
    }
  }
};
</script>

<style lang="scss">
.personal-info {
  width: 100%;
  min-height: 100%;
  position: relative;
  background: url("../../assets/images/personel-info-bg.png");
  background-size: auto 100%;
  padding: 100px 0;
  .info-card {
    width: 872px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 7px;
    margin: 0 auto;
    padding: 90px 180px 50px 204px;
    .avatar-box {
      margin-bottom: 50px;
      text-align: center;
      p {
        color: #db815a;
        font-size: 12px;
        cursor: pointer;
        font-family: PingFang SC;
        font-weight: 300;
        margin-top: 10px;
        position: relative;
        .el-upload-dragger {
          background: none;
          border-radius: 6px;
          border: none;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          overflow: hidden;
        }
      }
    }
    .el-form-item {
      // margin-bottom: 20px;
      .submit {
        margin-top: 60px;
        width: 322px !important;
        height: 38px !important;
        font-size: 17px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        color: #ffffff;
      }
      .line {
        text-align: center;
        color: #969696;
      }
      .el-input__inner,
      .el-textarea__inner {
        background: transparent;
        border: 1px solid #a0a0a0;
        font-size: 17px;
        font-family: PingFangSC-Light;
        font-weight: 300;
        color: #969696;
      }
      .el-input__inner {
        height: 36px;
        line-height: 36px;
      }
      .el-input__count {
        background: transparent;
        height: 28px;
        line-height: 28px;
        right: 5px;
      }
      .el-button--primary {
        width: 100%;
        border: none;
        border-radius: 50px;
        background-color: #ba001b;
      }
      .el-radio-group {
        margin-left: 109px;
        .el-radio__label {
          color: #ffffff;
        }
        .el-radio__inner {
          border: 2px solid #ffffff;
          background-color: #a0a0a0;
          &::after {
            background-color: #a0a0a0;
          }
        }
        .el-radio {
          margin-right: 100px;
        }
      }
      .el-radio__input.is-checked {
        .el-radio__inner {
          background-color: #e6767b;
          &::after {
            background-color: #e6767b;
          }
        }
      }
      .el-form-item__label {
        text-align: left;
      }
    }
    .input-must:after {
      position: absolute;
      top: 3px;
      right: -16px;
      content: "*";
      font-size: 22px;
      color: #f56c6c;
    }
    .radio-must:after {
      position: absolute;
      top: 14px;
      right: -16px;
      content: "*";
      font-size: 22px;
      color: #f56c6c;
    }
  }
}
</style>
